#colour_picker input {
    
    display: block;
    box-sizing: border-box;
    width: 90px;
    height: 40px;
    padding: 6px;
    border: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #eee;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
  }

#prefix {
  display: block;
  padding: 6px;
  height: 40px;
  border: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
}